<template>
	<div>
		<div class="mh100 bg-white boderra8 box-shadow0010">
			<div class="h96 paddlr30 display-flex  " style="background-color: #DCFF03;">
				<div class="display-flex w100 justify-content-between align-items-center">
					<div class=" fz14 co-333333 align-items-center">
						<div class=" column h100 marl20 align-items-center flex-column ">
							<div class=" fz20 co-000000 display-block marb10">{{user_info.point}}</div>
							<div class=" fz14 co-333333  ">积分余额</div>
						</div>
					</div>
					<div class="co-010085 align-items-center flex-column">
						<div class="title-item center cure-pointer fz14 " style="background: #010085;"
							@click='shows=true'>
							积分明细
						</div>
						<div class="mart10 fz12 co-010085 align-items-center cure-pointer" @click='show=true'>积分规则</div>
					</div>
				</div>
			</div>
			
			<div class="h54 paddlr30 display-flex align-items-center">
				<div class=" fz16 co-333333   font-blod">积分兑换</div>
			</div>
			
			<div class="paddt20 paddb30 paddlr20 flex-wrap" style="overflow:auto;height: 950px;"
				v-infinite-scroll="load1">
				<div class="class-item marb30 marr32" v-for="(item, i) in lists" :key="i" @click="godetails(item)">
					<div>
						<img class="class-item-pic" :src="item.image" alt="" />
					</div>
					<div class="fz20 co-333333 paddlr15 h80 align-items-center">
						<div class="fz20 co-333333 font-blod two-point clas-name">{{item.title}}</div>
					</div>
					<div class=" paddlr15 marb20 align-items-center "  v-if="item.avatar">
						<div class="justify-content-center align-items-center"> 			
							  <el-image  style='width: 30px; height: 30px;border-radius: 50%;' :src="item.avatar" fit="fit"></el-image>
							<div class=" fz14 co-A8A8A8 marl10 one-point ">{{item.nickname}}</div>
						</div>
						<div class=" fz14 co-A8A8A8 marl15 ">{{item.favorite}}推荐 · {{item.comment}}评论 · {{item.create_time}}</div>
					</div>
				</div>
				<loadMore :state="state" loading_icon></loadMore>
			</div>
		</div>
		<!-- //分享弹框 -->
		<el-dialog title="" :append-to-body='false' :lock-scroll='false' v-model="show" :center='true' :width='750'
			:show-close='true'>
			<div class="align-items-center flex-column paddlr20" style="overflow:auto;height: 700px;">
				<div class="h54 paddlr30  align-items-center">
					<div class=" fz18 co-333333   ">积分规则</div>
				</div>
				<div v-html="html" class="html-box  content-detail">
				</div>
			</div>
		</el-dialog>

		<!-- //积分明细 -->
		<el-dialog title="" :append-to-body='false' :lock-scroll='false' v-model="shows" :center='true' :width='750'
			:show-close='true'>
			<div class="flex-column  justify-content-center paddlr20">
				<div class="justify-content-center">
					<div class="h54 paddlr30  align-items-center">
						<div class=" fz18 co-333333   ">积分明细</div>
					</div>
				</div>
			</div>
			<div class='paddlr10' style="overflow:auto;height: 600px;" v-infinite-scroll="load">
				<div class="h80 align-items-center justify-content-between" v-for="(item, i) in list" :key="i">
					<div class=" flex-column">
						<div class=" fz16 co-333333 ">{{item.title}}</div>
						<div class=" fz16 co-A8A8A8 mart10 ">{{item.type==1?'收入':'支出'}}</div>
					</div>
					<div class=" flex-column align-items-center">
						<div class=" fz16 co-333333   ">{{item.create_date}}</div>
						<div class=" fz16 co-A8A8A8    mart10">{{item.point}}</div>
					</div>
				</div>
				<loadMore :state="state1" loading_icon></loadMore>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				shows: false,
				show: false,
				user_info: {

				},
				lists: [],
				list: [],
				page: 0,
				state: 0,
				loading: false,
				html:{},
				state1: 0,
			}
		},
		mounted() {
			this.$req({ 
			method: 'get',
			url: '/api/member',
			success: res => {	
				this.user_info = res
			},
			fail: error => {
				
			}
			});
			this.$req({
			method: 'get',
			data:{
				name:'point_rule'
			},
			url: '/api/config',
			success: res => {
					this.html = res.point_rule;		
			},
			fail: error => {}
			});
			
			this.Memberrecord();
			this.getlist();
		},
		methods: {
			getlist(){
			
				if (this.loading || this.state > 1) return;
				let page = parseInt(this.page) + 1;
				this.loading = true;
				this.state = 1;
				let size = 20;
				this.$req({
					method: 'get',
					data: {
						point:1,
						page: page,
						size: size
					},
					url: '/api/course',
					success: res => {
						let list = res;
						if (page == 1) {
							this.lists = list;
						} else {
							this.lists = this.lists.concat(list);
						}
						this.page = page;
						if (list.length == size) {
							this.state = 1;
						} else if (list.length > 0) {
							this.state = 2;
						} else if (this.lists.length == 0) {
							this.state = 3;
						}
						this.loading = false;
					},
					fail: error => {
						if (this.list.length == 0) {
							this.state = 3;
						} else {
							this.state = 2;
						}
						this.loading = false;
					}
				});
			},
			Memberrecord() {	
				if (this.loading || this.state1 > 1) return;
				let page = parseInt(this.page) + 1;
	
				this.state1 = 1;
				let size = 10;
				this.$req({
					method: 'get',
					data: {
						page: page,
						size: size
					},
					url: '/api/article/point',
					success: res => {
						let list = res;
						this.list = list;
						if (list.length == size) {
							this.state1 = 1;
						} else if (list.length > 0) {
							this.state1 = 2;
						} else if (this.list.length == 0) {
							this.state1 = 3;
						}
				
					},
					fail: error => {
						if (this.list.length == 0) {
							this.state1 = 3;
						} else {
							this.state1 = 2;
						}
					}
				});
			},
			load() {
				if (this.state1 > 1 || this.loading) return false;
				this.Memberrecord();
			},
			load1() {
				if (this.state > 1 || this.loading) return false;
				this.getlist();
			},
			godetails(item) {
				// 1素材市场 2研图社 3竞赛信息 4院校指南 5项目灵感
				let data = item.type;
				let id = item.id
				let type = '';
				let url = '/Detail';
				if (data == 'source') {
					type = 1
				} else if (data == 'picture') {
					type = 2
				} else if (data == 'competition') {
					type = 3
				} else if (data == 'school') {
					type = 4
				} else if (data == 'inspiration') {
					type = 5
				}
				else if (data == 'teacher') {
					this.$router.push({
						path: '/TeacherDetail',
						query: {
							type: type,
							id: id
						}
					})
					return
				}
				else if (data == 'agency') {
					this.$router.push({
						path: '/SincerityDetail',
						query: {
							type: type,
							id: id
						}
					})
					return
				}
				else if (data == 'course') {
					this.$router.push({
						path: '/ClassDetail',
						query: {
							type: type,
							id: id
						}
					})
					return
				}
				
				this.$router.push({
					path: url,
					query: {
						type: type,
						id: id
					}
				})
			}
		}
	}
</script>

<style scoped>
	.html-box>>>img {

		display: block;
		margin: 5px auto;
		max-width: 100% !important;
		height: auto !important;
	}

	.html-box>>>p {

		max-width: 100%;
		word-break: break-word;
		font-size: 16px;
		font-weight: 500;
		color: #000000;
		margin: 30px 0;
	}

	.class-item {
		width: 283px;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		border-radius: 8px;


	}

	.class-item-pic {
		width: 285px;
		height: 220px;
	}

	.clas-name {
		max-height: 58px;
	}

	.class-item:nth-child(3n) {
		margin-right: 0;
	}

	.class-item:hover {
		cursor: pointer;
		border: 1px solid #DCFF03;
	}

	.class-item:hover .clas-name {
		color: #010085;
	}

	.app_06 {
		width: 52px;
		height: 52px;
		border-radius: 50%;
	}

	.img {
		width: 59px;
		height: 22px;
	}

	.title-item {
		min-width: 70px;
		padding: 0 5px;
		height: 28px;
		border-radius: 3px;
		color: #DCFF03;
	}

	.round {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		background: #DCFF03;
		border-radius: 50%;
	}
</style>
